// 
// accessories.scss
//

// Accessoiries (Custom)
.br-solid {
    padding-right: 0.7rem;
    border-right: 1px solid $revamp-gray-10;
    display: inline-block;
    line-height: 12px;
}

.bb-solid {
    border-bottom: 1px solid $revamp-gray-10 !important;
}

.bl-solid {
    border-left: 1px solid $revamp-gray-10;
}

.bt-none {
    border-top: none !important;
}

.b-solid {
    border: 1px solid;
}

.br-round-30 {
    border-radius: 30px !important;
}

.mr-3p {
    margin-right: 3px;
}


@each $color, $value in $theme-colors {
    .border-bg-#{$color} {
        background: ($value);

        &:before {
            border-color: darken(($value),5%) transparent transparent;
        }
    }
    .border-color-#{$color} {
        border-color: ($value);

        &:before {
            border-color: darken(($value),5%) transparent transparent;
        }
    }
}

.li-first-inline-block {
    display: inline-block;
}

.box-shadow-0360 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.box-shadow-none {
    box-shadow: none;
}

.pd-content-page {
    padding: $topbar-height 12px 0px;
}

.pd-card-b-1 {
    padding: 2.3rem 1.5rem 0.5rem 1.5rem;
}

.pd-card-tb-1 {
    padding: 0.5rem 1.5rem;
}

.pd-tittle-tb {
    padding: 1rem 1.5rem 0.75rem 1.5rem;
}

.bg-border-transparent {
    background-color: transparent;
    border: none;
}

.lh-38 {
    line-height: 38px;
}

.pt-100 {
    padding-top: 100px;
}

.side-nav .bt-side-nav-link {
    color: #b8b8b8;
    display: block;
    padding: 10px 30px;
    font-size: .9375rem;
    position: relative;
    -webkit-transition: all .4s;
    transition: all .4s;
}

.side-nav .bt-side-nav-link i {
    display: inline-block;
    /* line-height: 1.0625rem; */
    margin: 0 10px 0 0;
    font-size: 1.1rem;
    vertical-align: middle;
    /* width: 20px; */
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .bt-side-nav-link {
    padding: 15px 10px;
    min-height: 56px;
    -webkit-transition: none;
    transition: none;

    i {
        font-size: 1.5rem;
        margin-right: 20px;
        margin-left: 6px;
    }

    span {
        display: none;
        padding-left: 10px;
    }
}

.datepicker table tr td span.new, .datepicker table tr td.new {
    color: #6c757d;
    opacity: .4;
    display: none;
}

.calendar-widget .datepicker-inline .datepicker-switch, .calendar-widget .datepicker-inline .next, .calendar-widget .datepicker-inline .prev, .calendar-widget table .datepicker-switch, .calendar-widget table .next, .calendar-widget table .prev {
    font-size: 1.1rem;
    background-color: transparent!important;
    border-radius: 0;
    color: $revamp-dark;
}

.calendar-widget .datepicker-inline tr th, .calendar-widget table tr th {
    background-color: transparent!important;
    color: $revamp-dark;
}

.header_filter {
    background-color: $revamp-white;
}

.sticky_header_filter {
    position: fixed;
    top: 60px;
    z-index: 1;
    margin-left: -40px;
    padding-left: 40px;
    padding-right: 114px!important;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
}
  
.sticky_header_filter + .content-list-meeting {
    padding-top: 74px;
}

.fixed-right-calendar {
    z-index: 1;
    position: fixed;
    float: right;
    right: 0;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #313d48;
    background-color: #ffffff;
}

.nav-pills>li>a, .nav-tabs>li>a {
    color: #ffc200;
    font-weight: 600;
}

.zabuto_calendar .table td {
    border-top: none;
}

div.zabuto_calendar >table.table {
    border-collapse: separate;
}

.flipped-icon {
    transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1)!important;
    -ms-transform: scaleX(-1)!important;
    margin-left: 4px!important;
}

.scroll_in_fixed {
    max-height: 210px;
    overflow-y: scroll;
}

.overflowY-hidden {
    overflow-y: hidden;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1!important;
    width: 100vw;
    height: 100vh;
    background-color: #313a46;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

@media (min-width: 1280px) {
    .modal-lg, .modal-xl {
        max-width: 1100px;
    }
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid transparent;
    border-radius: 15px;
    outline: 0;
}

.select2-container--default .select2-search--dropdown {
    display: none;
}

.form-group > #agenda-form-icon > i {
    position: absolute;
    right: 2px;
    font-size: 36px;
    top: -7.5px;
    z-index: 5;
}

.form-group > #date-form-icon > i {
    position: absolute;
    font-size: 22px;
    top: 3.7px;
    left: 5px;
    z-index: 5;
}

span[title=StartTimeHours] {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none !important;
}

span[title=StartTimeMinutes] {
    border-radius: 0px !important;
    border-right: none !important;
}

span[title=StartTimeZone] {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.dropzone {
    border: 2px dashed $primary !important;
    // background: #fff;
    // border-radius: 6px;
    // cursor: pointer;
    min-height: 130px !important;
    // padding: 15px 20px;
}

.dropzone .dz-message {
    // text-align: center;
    margin: 0.5rem 0 !important;
}

.icon-inside-input-right {
    width: 40px;
    border: 1px solid #dee2e6;
    border-left: none;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.input-with-icon-right {
    width: calc(100% - 40px);
    border-right: none;
}

.border-tbright-25 {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.icon-inside-date-form {
    position: absolute;
    font-size: 22px;
    top: 33px;
    left: 17px;
}

.select2-selection__arrow > b {
    display: none;
}

.chevron-down-select {
    font-size: 18px;
    position: absolute;
    top: 5px;
    right: 3px;
}